@import url("https://fonts.googleapis.com/css?family=Nunito:600,700&display=swap");
* {
  box-sizing: border-box;
}

body {
  font-family: Nunito, Roboto, Arial, sans-serif;
  background: #4D6FE5;
  height: 100vh;
}

#app {
  height: 100%;
  display: flex;
  justify-content: center;
}

.container {
  text-align: center;
  color: #fff;
  margin-top: 100px;
}
.container > header {
  font-size: 1.3rem;
  font-weight: 700;
  margin: 0 auto 60px auto;
  position: relative;
  height: 25px;
  width: 250px;
  overflow: hidden;
}

.header-headings {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.header-headings > span {
  margin: 3px 0;
}
.header-headings.sign-in {
  transform: translateY(0);
}
.header-headings.sign-up {
  transform: translateY(-25px);
}
.header-headings.forgot {
  transform: translateY(-50px);
}

.options {
  display: flex;
  align-items: center;
  width: 350px;
}
@media screen and (max-width: 380px) {
  .options {
    width: 100%;
  }
}
.options > li {
  cursor: pointer;
  opacity: 0.5;
  transition: all 0.2s ease;
}
.options > li:hover {
  opacity: 1;
}
.options > li.active {
  opacity: 1;
}
.options > li:nth-of-type(2) {
  margin-left: 15px;
}
.options > li:last-of-type {
  margin-left: auto;
}

.account-form {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.account-form-fields {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.account-form-fields.sign-in {
  /* max-height: 123px; */
}
.account-form-fields.sign-up {
  max-height: 250px;
}
.account-form-fields.forgot {
  max-height: 61px;
}
.account-form-fields > input {
  border: 0;
  margin-bottom: 10px;
  padding: 15px;
  font-size: 1rem;
  font-family: Nunito, sans-serif;
  color: #000;
  /* border-radius: 5px; */
  border: 1px solid #d9d9d9;
    border-radius: 2px;
    -webkit-transition: all .3s;
    transition: all .3s;
}
.account-form-fields > input::-moz-placeholder {
  color: #aaa;
}
.account-form-fields > input:-ms-input-placeholder {
  color: #aaa;
}
.account-form-fields > input::placeholder {
  color: #aaa;
}
.account-form-fields > input::-webkit-input-placeholder {
  color: #aaa;
}
.account-form-fields > input:-ms-input-placeholder {
  color: #aaa;
}
.account-form-fields > input:focus {
  outline: none;
}

.btn-submit-form {
  border: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  padding: 15px 0;
  border-radius: 5px;
  color: #fff;
  font-size: 1rem;
  font-family: Nunito, sans-serif;
  background: #2B76D9;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.13);
  transition: all 0.2s ease;
}
.btn-submit-form:hover {
  background: #4468e4;
}
.btn-submit-form:active, .btn-submit-form:focus {
  outline: none;
  background: #2e56e0;
}

footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 30px 0;
  text-align: center;
}
footer > a {
  color: #fff;
  font-weight: 700;
}
::-webkit-scrollbar {
    width: 6px;
  }
   
  ::-webkit-scrollbar-track {
    /* background-color: #aaa; */
  }
   
  ::-webkit-scrollbar-thumb {
    background-color: #2B76D9;
    border-radius: 8px;
  }
  
  body {
    font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  }
  
  .login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    /* background: #2B76D930; */
    background: linear-gradient(to right, #ece9e6, #ffffff);
  }
  .login-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    /* max-width: 1000px; */
    background-color: white;
    box-shadow: 0 0 40px rgba(0,0,0,0.16);
    overflow: hidden;
    margin: 0 auto;
    border-radius: 12px;
  }
  #login-form {
    flex: 1 0 100%;
    max-width: 480px;
    width: 100%;
    padding: 60px;
  }
  #login-form p {
    margin-bottom: 30px;
  }
  #login-form p.form-title {
    color: #333333;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 42px;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 0;
  }
  #login-form .ant-form-item-label > label.ant-form-item-required::before {
    display: none;
  }
  .ant-form-item-control-input-content {
    text-align: left;
  }
  #login-form .ant-input-affix-wrapper {
    padding: 12px 15px;
  }
  #login-form_username {
    height: 48px;
  }
  #login-form .ant-btn {
    height: 42px;
    letter-spacing: 1px;
    border-radius: 6px;
  }
  .login-form-button {
    width: 100%;
  }
  .illustration-wrapper {
    display: flex;
    align-items: flex-end;
    max-width: 800px;
    min-height: 100%;
    background-color: #fffdf2;
  }
  .illustration-wrapper img {
    display: block;
    width: 100%;
  }
  @media screen and (max-width: 1023px) {
    .login-box {
      flex-direction: column;
      box-shadow: none;
    }
    .illustration-wrapper {
      max-width: 100%;
      min-height: auto;
    }
    #login-form {
      max-width: 100%;
    }
  }