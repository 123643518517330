.App{
    /* padding: 0px 20px 0px 0px; */
    /* background: linear-gradient(90deg, #def3ff, #e3f5ff, #e8f6ff, #ecf8ff, #f1faff, #f6fcff, #fafdff, #ffffff);
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 100%; */
    background-color: white;
    height: 100vh;
}

/*
Splash
*/
.splash{}
.splash img{
    height: 100px;
}

/*
VIDEO PLAYER
*/
.player{
    width: 100%;
    height: 55%;
    background: #000;
}
.player video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.player .ctrls{
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 90px;
    background: rgba(62, 62, 62, 0.54);
    z-index: 9;
    padding: 0px 15px;
}
.player .ctrls .pp{
    border: 0px;
    width: 50px;
    height: 50px;
    border-radius: 10px;
    background: #ea5252;
    color: #fff;    
}
.player .ctrls .timer{
    flex: 1;
    margin: 0px;
    text-align: center;
}
.player .ctrls .slider{
    flex: 3;
}
.player .ctrls .slider .prog{
    width: 100%;
    height: 10px;
    background: #ffffff;
    border-radius: 10px;
}
.player .ctrls .slider .bar{
    width: 40%;
    height: 10px;
    background: #ea5252;
    border-radius: 10px;
}
.player .ctrls .slider .bar .knob{
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 100%;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    box-shadow: 0px 0px 5px #000;
}
.player .ctrls .vol{
    border: 0px;
    width: 50px;
    height: 50px;
    border-radius: 10px;
    background: rgba(234, 82, 82, 0);
    color: #fff;
    margin: 0px 10px;
}
.player .ctrls .fs{
    border: 0px;
    width: 50px;
    height: 50px;
    border-radius: 10px;
    background: rgba(234, 82, 82, 0);
    color: #fff;
    margin: 0px 10px;
}


/*
SIDEBAR
*/
.sidebar{
    width: 275px;
    background-color: #f2f2f2;
    /* position: static ; */
    position: relative;
}

.sidebar .logo{
    padding: 20px 25px;
}
.sidebar .logo img{
    height: 40px;
}

.sidebar .nav{
    padding: 50px 35px;
}
.sidebar .nav li{}
.sidebar .nav li a{
    padding: 0.6rem 1rem 0.6rem 1rem;
    margin-bottom: 6px;
}
.sidebar .nav li a .ico{
    margin-right: 10px;
}
.sidebar .nav li a .lbl{}

.linkactive{
    background-color: #2B76D9;
    border-radius: 10px;
    -webkit-box-shadow: 0 0 10px rgb(197, 197, 197);
    -moz-box-shadow: 0 0 10px rgb(197, 197, 197);
    color: #fff;
    line-height: 1;
}

.sidebar .updated-course{
    padding: 0px 35px;
    margin: 20px 0px;
}
.sidebar .updated-course .ico{
    background: #ea5252;
    padding: 15px;
    border-radius: 22px;
    margin-right: 10px;
}
.sidebar .updated-course .lbl{}
.sidebar .updated-course .lbl .author{}

.course-stats, .sidebar .stats{
    padding: 70px 50px;
}
.course-stats .stats-box, .sidebar .stats .stats-box{
    flex-direction: column;
    width: 110px;
}
.course-stats .stats-box .ico, .sidebar .stats .stats-box .ico{
    margin-bottom: 15px;
}
.sidebar .stats .stats-box .val{}
.sidebar .stats .stats-box .lbl{}
.course-stats .ico-points, .sidebar .stats .stats-box .ico-points{color: #1aab53;}
.course-stats .ico-battery, .sidebar .stats .stats-box .ico-battery{color: #ea5252;}

.sidebar .me{
    padding: 20px 40px;
}
.sidebar .me .photo{
    width: 50px;
    height: 50px;
    border-radius: 25px;
    overflow: hidden;
    margin-right: 10px;
    background: #eee;
}
.sidebar .me .photo img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.sidebar .me .lbl{}

.sidebar .me .link{}
.sidebar .me .link .ico{
    width: 50px;
    height: 50px;
    background: #ea5252;
    border-radius: 100%;    
    margin-right: 10px;
}
.sidebar .me .link .ico:before{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.sidebar .me .link .lbl{}

.app-content{
    flex: 2;
    overflow-y: scroll;
    /* padding: 20px 40px 20px 20px; */
}


/*
HOME PAGE
*/
.home-page{
    padding: 20px 40px 20px 20px;
    overflow: scroll;
}
.home-page .section{}
.home-page .section .title{
    margin-bottom: 20px;
}
.home-page .section .tutors{

}
.home-page .section .tutors .tutor{
    border: 2px #ea5252 solid;
    background: #fff;
    width: 60px;
    height: 60px;
    overflow: hidden;
    margin-right: 20px;
    border-radius: 50px;
    padding: 3px;
}
.home-page .section .tutors .tutor img{
    width: 100%;
    height: 100%;
    border-radius: 55px;
}
.home-page .section .tutors .tutor:hover{}

.section-b{
    margin-top: 50px;
}
.section .courses{}
.section .courses a{
    flex: 1;
    margin-right: 25px; 
}
.section .courses .block{
    /*width: 250px;*/
    height: 275px;
    background: #e2e2e2;
    border-radius: 20px;
    background-size: cover !important;     
}
.section .courses a:last-child{
    margin-right: auto;
}
.section .courses .block .user{
    padding: 20px;
    z-index: 1;
}
.section .courses .block .user .pic{
    width: 40px;
    height: 40px;
    border: 2px #fff solid;
    border-radius: 50%;
    padding: 3px;
    margin-right: 10px;
}
.section .courses .block .user .pic img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.section .courses .block .user .meta{}
.section .courses .block .user .meta .name{}
.section .courses .block .user .meta .uname{}

.section .courses .block  .dura{
    top: 95px;
    left: 20px;
    background: #ea5252;
    line-height: 1;
    padding: 8px 15px;
    border-radius: 10px;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.33);
    z-index: 1;
}
.section .courses .block  .course-title{
    bottom: 15px;
    left: 15px;
    right: 15px;
    background: rgba(0, 0, 0, 0.75);
    padding: 15px 20px;
    border-radius: 20px;
    text-shadow: 1px 1px 1px #000;
}

.home-page .top-tutors{
    flex-wrap: wrap;
}
.home-page .section .user-block{
    margin: 0px 50px 30px 0px;
}
.home-page .section .user-block .pic{
    width: 40px;
    height: 40px;
    border: 2px #fff solid;
    border-radius: 50%;
    padding: 3px;
    margin-right: 10px;
}
.home-page .section .user-block .pic img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.home-page .section .user-block .meta{}
.home-page .section .user-block .meta .name{}
.home-page .section .user-block .meta .uname{}

/*
RIGHTBAR
*/
.rightbar{
    flex: 1;
    padding: 20px 0px;
}

.rightbar .search-box{
    margin: 10px 0px;
}
.rightbar .search-box .qry{
    flex: 1;
    border: 0px;
    padding: 15px;
    background: #f1f1f1;
    margin-right: 10px;
    border-radius: 5px;
}
.rightbar .search-box .qry:focus{
    background: #e0e0e0;
}
.rightbar .search-box .go{
    border: 0px;
    background: #ea5252;
    padding: 0px 20px;
    border-radius: 5px;
}
.rightbar .search-box .go:hover{
    background: #d24444;
}
.rightbar .courses{
    flex-direction: column;
}
.rightbar .courses .cols{
    flex-direction: row;
}
.rightbar .course-a .block{
    width: 100%;
    height: 250px;
    margin-bottom: 25px;
}
.rightbar .courses .cols .block{
    height: 290px;
}

.rightbar .special-offer{
    background: #e5fcfb;
    height: 185px;
    margin-top: 14px;
    border-radius: 20px;
    overflow: hidden;
}
.rightbar .special-offer .meta{
    padding: 30px;
    flex: 1;
    z-index: 2;
}
.rightbar .special-offer .meta .h1{color: #ea5252;}
.rightbar .special-offer .meta .h2{
    margin-top: 6px;
    color: #06a9a2;
}
.rightbar .special-offer .meta button{
    background: #fff;
    border: 0px;
    border-radius: 10px;
    padding: 14px 20px;
    margin-top: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
.rightbar .special-offer .meta button:hover{
    background: #ea5252;
    color: white;
}
.rightbar .special-offer .vector{
    padding-top: 30px;
    width: 150px;
    z-index: 2;
}
.rightbar .special-offer .vector img{}
.rightbar .special-offer .ring{
    width: 100px;
    height: 100px;
    background: #90e6e3;
    border-radius: 100%;
    right: -90px;
    top: 50%;
    transform: translateY(-50%);
    border: 40px rgba(255, 255, 255, 0.45) solid;
}

/*
COURSE PREVIEW
*/
.course-page{
    display: flex;
    flex-direction: column;
}

.course-info{
    width: 27%;
}
.course-page .tutor{}
.course-page .tutor .pic{
    width: 40px;
    height: 40px;
    border-radius: 10px;
    background: #eee;
    margin-right: 10px;
}
.course-page .tutor .pic img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
.course-page .tutor .meta{}
.course-page .tutor .meta .name{}
.course-page .tutor .meta .uname{}
.course-page .course-meta{
    padding: 40px 0px;
}
.course-page .course-meta .title{
    font-weight: 600;
}
.course-page .course-meta .about{}

.course-videos{
    flex-direction: column;
    align-items: flex-start;
    margin: 20px 0px;
}
.course-videos a{
    margin: 10px 0px;
}
.course-videos a .id{
    background: #ea5252;
    width: 50px;
    height: 50px;
    border-radius: 14px;
    padding: 14px 20px;
    box-sizing: border-box;
    margin-right: 10px;
}
.course-videos a .meta{}
.course-videos a .meta .lbl{}
.course-videos a .meta .dur{}
.course-videos a:hover{}

.course-preview{
    width: 73%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
}

.extra-block{}
.chat{
    flex: 1;
    margin-right: 50px;
}
.chat .messages{
    flex-direction: column;
    text-align: left;
    justify-content: flex-start;
    padding: 30px 0px;
}
.chat .messages .bubble{
    align-self: flex-start;
    margin: 7px 0px;
    width: 100%;
}
.chat .messages .bubble .txt{
    background: #d6d6d6;
    line-height: 1;
    padding: 14px;
    border-radius: 20px 20px 20px 3px;
}
.chat .messages .bubble-mine{
    text-align: right;
}
.chat .messages .bubble-mine .txt{
    border-radius: 20px 20px 3px 20px !important;
    background: #272727;
    color: #fff;
}

.now-watching{
    flex: 1;
}
.now-watching .tooltip{
    background: #ea5252;
    top: 50px;
    right: 0px;
    padding: 8px 15px;
    border-radius: 10px;
}
.now-watching .you-list{margin-top :25px;}
.now-watching .you{
    margin-bottom: 30px;
}
.now-watching .you .pic{
    width: 40px;
    height: 40px;
    margin-right: 12px;
    background: #ddd;
    border-radius: 100%;
}
.now-watching .you .pic img{
    width: 100%;
    height: 100%;
    border-radius: 100%;
}
.now-watching .you .meta{}
.now-watching .you .meta .name{}
.now-watching .you .meta .uname{}


/*
OAUTH VIEW
*/
.oauth-view{
    width: 301px;
    margin: 100px auto;
}
.oauth-view-verify{width: 420px;}
.oauth-view .otitle{}
.oauth-view .oline{
    margin-bottom: 30px;
    margin-top: 10px;
}
.oauth-view .iput{
    width: 100%;
    border: 1px #b1b1b1 solid;
    padding: 20px;
    border-radius: 3px;
    margin: 10px 0px;
    box-sizing: border-box;
}
.oauth-view .button{
    width: 100%;
    border: 1px #d43a3a solid;
    padding: 20px;
    border-radius: 3px;
    margin: 10px 0px;
    box-sizing: border-box;
    background: #ea5252;
}
.oauth-view .button:hover{
    background: #e06161;
}
.oauth-view .__phonesigner__{
    margin: 10px 0px 20px 0px;
}