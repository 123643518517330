.home {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}   

.activeExam {
    /* box-shadow: 0px 0px 30px -9px rgba(0,0,0,0.71); */
    /* transform: "scale3d(1.01, 1.01, 1)",
    transition: "transform 0.2s ease-in-out" */
    border: 5px rgb(93, 192, 238) solid;
}

.imageblur {

  /* filter: blur(2px);
  -webkit-filter: blur(2px);
  transition: 0.3s filter linear;
  -webkit-transition: 0.3s -webkit-filter  */
  height: 30vh !important;
}

.material-name {
    height: 100%;
    background: #ffffff30;
    border-radius: 1.5rem;
    align-items: center;
    display: flex;
    justify-content: center;
}