    body {
        margin: 0;
    }
  
  .document-editor {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }
  
  .tox.tox-tinymce {
    height: 100% !important;
    width: 100%;
  }
  
  .font {
    font-family: Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
    font-weight: 300;
    font-size: 15px;
    color: #fff;
  }