.sidebarclose {
   
    width: 0;
    transition: 0.5s ease-out;
    overflow: hidden;
}

.sidebaropen {
    width: 275px;
    transition: 0.5s ease-out;
}