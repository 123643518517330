.video_wrapper {
    width: 100%;
    margin-top: 1rem;
}

.video_wrapper iframe {
    width: 50vw;
    height: 60vh;
    /* border-radius: 25px; */
}


.vimeo-wrapper {
    width: 100%;
    height: 60vh;
}

.youtubevideo-container {
    width: 50vw;
    height: 60vh;
    display: flex;
    align-self: flex-start;
    border-radius: 15px;
    padding:0.5rem;
}

.video_wrapper iframe {
    border-radius: 15px;
}

.accordian-material-container {
    width: 100%;
    margin-bottom: 3px;
    padding: 0.4rem;
    padding-left: 30px;
    display: flex !important;
    flex-direction: row !important;
}

.accordian-material-container:hover {
    /* background-color: rgba(0,0,0,.08); */
    background-color: #2b76d940;
    cursor: pointer;
}

.accordian-material-container-active {
    width: 100%;
    margin-bottom: 3px;
    padding: 0.4rem;
    padding-left: 30px;
    background-color: #2b76d940;
    display: flex !important;
    flex-direction: row !important;
}

.accordS #anim {
    width: 0.4rem;
    background-color: #2b76d9;
    display: none;
}

.accordS:hover #anim{
    display: block;
}

.search-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.chapter-wrapper {
    width: 100%;
    margin-bottom: 20px;
}

.material-wrapper {
    width: 100%;
    margin-top:60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 6rem;
}

.vimeo-wrapper iframe .vp-center {
    justify-content: flex-start !important;
}


body {
    margin: 0;
    padding: 0;
  }
  
  .container {
    width: 100%;
    height: 0;
    padding: 0 0 56.25%;
    position: relative;
    background: #000;
    color: white;
    display: table;
  }
  .container .player {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
  
  .video-thumb {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: calc(9/16*100vw);
    background: #000;
    color: white;
  }
  .video-thumb img {
    max-width: 100%;
    max-height: -webkit-fill-available;
  }